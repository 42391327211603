<template lang="pug">
component(v-if="item?.title || item?.name" :is="component" :href="fixDrupalLink(uri)")
  slot(:item="item") {{item.title || item.name}}
</template>
<script setup>
  import { NuxtLink } from '#components'

  const props = defineProps({
    item: { type: Object, required: false },
  })

  let component = NuxtLink
  const uri = props.item.full_url
    ? props.item.full_url
    : props.item.path
    ? props.item.path.alias
    : props.item.alias

  if (/^(?:[a-z]+:)?\/\//i.test(uri)) {
    component = 'a'
  }
</script>
